import React,{useEffect,useContext} from "react";
import { BulkUploadListingsTable } from "./bulkupload/BulkUploadListingsTable";
import "./inventory.scss";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { DataContext } from "../context/DataProvider";
const BulkUpload = () => {
  const {setTopHeader}=useContext(DataContext)
  useEffect(()=>setTopHeader('Inventory'),[])
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: "8%",
          left: "15.8%",
          width: "84vw",
          height: "120vh",
          background: "#F6F6F6 0% 0% no-repeat padding-box",
        }}
      >
        <div className="toptableheading">
          <div style={{ position: "relative", left: "2.5%", fontSize: "19px" }}>
            <div>Bulk Upload</div>
          </div>
          <div style={{ position: "relative", right: "2.7%" }}>
            <span>Inventory</span>{" "}
            <span>
              <MdOutlineArrowForwardIos />{" "}
            </span>
            <span className="subMenu_subheading">Bulk Upload</span>
          </div>
        </div>

        <div
          className="bulkimportTable"
          style={{ width: "79.5vw", height: "100vh" }}
        >
          <BulkUploadListingsTable />
        </div>
      </div>
    </>
  );
};

export default BulkUpload;
