import  React,{useState} from 'react';
// import './inventory.scss'
import WarningIcon from "../../Images/warning.png"
import SuccessFullydeletedIcon from "../../Images/successDeleted.png"
import { Button,styled, Box } from '@mui/material'
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import axiosInstance from "../../../service/axiosInstance"
import { useNavigate } from 'react-router-dom';


const Dialog1=styled(Dialog)`& div > div{left:2%;border-radius:30px}`
const Box2=styled(Box)`width:30vw;height:12vh;text-align:center;margin:20px`
const Box3=styled(Box)`width:30vw;height:6vh;text-align:center;margin:20px;& button{margin-right:20px;text-transform:capitalize;width:6vw}`
const Box4=styled(Box)`width:30vw;height:6vh;text-align:center;margin:20px;& button{text-transform:capitalize;width:6vw;background:#13A74B}`
const Typography1=styled(Typography)`text-align: center;width:35vw;height:5vh;margin-top:10px;
font: normal normal 600 20px/35px Poppins;
letter-spacing: 0px;
color: #282C45;`

const Typography2=styled(Typography)`text-align: center;width:35vw;height:5vh;margin-top:1px;
font: normal normal 600 20px/35px Poppins;
letter-spacing: 0px;
color: #282C45;`

function SimpleDialog(props) {
  const navigate=useNavigate()
  const { onClose, selectedValue, open,deleteTempName, setForReload} = props;
  const [showConfirm,setShowConfirm]=useState(true)
  const handleClose = () => {
    onClose(selectedValue);
  };


  const yesHandle=async()=>{
    try {
      await axiosInstance({
        url: "/deleteTemplate",
        method: "POST",
        data: {templatename:deleteTempName},
      }).then((response) => {
        console.log("deleted", response)
        setForReload(true)
        if(response){
          setShowConfirm(!showConfirm)
        }
      })
    }catch(e){
      console.log(e)
    }
  }

  return (
    <Dialog1 onClose={handleClose} open={open}>
      
       {showConfirm ?  
       <Box>
             <Box2><img src={WarningIcon} width="90px" alt="warning..."/></Box2>
             <Typography1>Are you sure you want to delete the listing? </Typography1>
             <Box3>
                <Button variant="contained"  
                  onClick={()=>onClose(false)}
                  style={{color:"black",background: "#E2E2E2",position:"relative",left:"10px"}}>
                  No
                </Button>
                <Button 
                      style={{position:"relative",left:"10px"}}
                      onClick={yesHandle} 
                      variant="contained" color="success">
                      yes
                 </Button>
            </Box3>
        </Box>:
         <Box>
                   <Box2><img src={SuccessFullydeletedIcon} width="110px" alt="warning..."/></Box2>
                   <Typography2>Successfully Deleted</Typography2>
                   <Box4>
                      <Button variant="contained" 
                       onClick={()=>{onClose(false)}}
                        color="success">
                       Ok
                      </Button>
                  </Box4>
        </Box>
        
        }
      

       
    </Dialog1>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function DeleteMappingPopper({deleteTempName,compoName,setForReload}) {
  const [open, setOpen] = React.useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    // setSelectedValue(value);
  };

  return (
    <div>
      
      <span onClick={handleClickOpen}>Delete Mapping</span>
     
      <SimpleDialog
        // selectedValue={selectedValue}
        setForReload={setForReload}
        deleteTempName={deleteTempName}
        compoName={compoName}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
