import React, { useState,useContext } from 'react'
import axios from 'axios'
import  {API_URL} from "../../../service/Api"
import { getAccessToken } from '../../../utils/common-utils'
import { useNavigate } from 'react-router-dom'
import BookcommFile from "../../../file/bookcommerce.xlsx"
import AbebookFile from  "../../../file/abebooks-template_EN.xls"
import AlibrisFile from "../../../file/alibris_upload_template_books_2.0.xls"
import ValorebookFile from  "../../../file/Valorebook.txt"
import BiblioFile from "../../../file/Biblio..xlsx"
import EcampusFile from "../../../file/ecampus.xlsx"
import AmazonFile from "../../../file/Amazon.xlsx"
import { DataContext } from '../../context/DataProvider';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '../../Images/upload.png'

const SelectSample={borderRadius:"10px",height:"6vh",width:"15vw", background: "#13A74B 0% 0% no-repeat padding-box",
font: "normal normal normal 15px/13px Poppins",color: "#FFFFFF",textAlign:"center",marginTop:"10px", paddingLeft:"12px"}
const optionStyle={background: "#FFFFFF 0% 0% no-repeat padding-box",
  boxShadow: "0px 0px 22px #8282824D",color:"grey",textAlign:"left",height:"10px"}
const templabelStyle={position: "absolute",zIndex: "99",top: "27px",left: "60px"}
const templabelStyle2={display:"none"}

export const GlobalFilter = ({ filter, setFilter,templateName }) => {
  const {setGetTemplateListingData}=useContext(DataContext)
  const navigate=useNavigate()
  const [sampleVal,setSampleVal]=useState("")
 

  //for download sample
  const onDownload = (val) => {
    const link = document.createElement("a");
    if(val==="Bookcommerce"){
    link.download = `Bookcommerce.xlsx`;
    link.href = BookcommFile;
    }

   else if(val === "AbeBooks"){
    link.download = `Abebooks.xlsx`;
    link.href = AbebookFile;
    }
    else if(val === "Alibris"){
      link.download = `Alibris.xlsx`;
      link.href = AlibrisFile;
      }
      else if(val === "Biblio"){
        link.download = `Biblio.xlsx`;
        link.href = BiblioFile;
        }
        else if(val === "Amazon.com"){
          link.download = `Amazon.xlsx`;
          link.href = AmazonFile;
          } 
          else if(val === "eCampus"){
            link.download = `eCampus.xlsx`;
            link.href = EcampusFile;
            }
            else if(val === 'ValoreBooks'){
              link.download = `Valorebook.txt`;
              link.href = ValorebookFile;
              } 
    else{
    link.download = `Valorebook.txt`;
    link.href = ValorebookFile;
    }
    link.click();
  };

  const onChangeSample=(val)=>{
    let newVal=val.substring(1)
    console.log(typeof newVal)
    setSampleVal(val) 
    onDownload(newVal)
}

  //file upload and submit
  const [file,setFile]=useState()
  const [tempName,setTempName]=useState('')
  const [open, setOpen] = useState(false)
  const [errormsg,setErrormsg]=useState('')
  const [notMapped,setNotMapped]=useState(false)
  const submitHandle = async(e) => {
    const fileData={}
    Object.assign(fileData,{"templatefile":file},{"tempname":tempName})
    e.preventDefault()
      const config = {
      headers: {
        'content-type': 'multipart/form-data',
         authorization: getAccessToken(),
      },
    };
    try{
      if(tempName && file){
        await axios.post(`${API_URL}/uploadFile`,fileData , config,{timeout: 2000}).then((response) => { 
            if(response.data.status==="succes"){
              setGetTemplateListingData(response.data.result)
              navigate("/templateListingTable")
          }

          if(response.data.status==="failed"){
            setNotMapped(true)
            setErrormsg("first add template and do mapping properly")
           }
        });
      }
      else{
        setOpen(true)
        setErrormsg("Please select template and file!")
      }
    }catch(e){
          setOpen(true)
          setErrormsg("something went wrong please try later")
    }

  }


  return (
    <>
      <div className='globalfiltersbulkupload'>
            <div >
            <label style={tempName ? templabelStyle2 : templabelStyle }>Template</label>
             <select
              name="item_condition" className='item_condition'
               value={tempName} onChange={(e) => setTempName(e.target.value)}
             >
               <option></option>
               {
                templateName.length>0 && templateName.map((name)=>{
                  return <option>{name}</option>
                })
               }
              <option>Bookcommerce</option>
             </select>
           
          </div>

               <div className='bulkImportChooseFile' style={{display:"flex"}}  >
                        <div style={{marginTop:"5px"}}>
                        <label htmlFor='file' style={{width:"230px", height:"35px",borderRadius:"20px",background:"#F1F1F1",justifyContent:"space-around",alignItems:"center",fontSize:"14px",padding:"2px"}}>
                          {file ? file.name.substring(0, 20) + '...' :"Choose a file to upload"}
                          <img src={UploadIcon} style={{width:'30px',height:"25px"}} alt='upload'/>
                        </label>

                         <input className='bulkImportChooseinput' onChange={(e)=>setFile(e.target.files[0])} type="file" id='file'/>
                         
                        </div>

                        <div style={{marginLeft:"8px"}}>
                          <button className='bulkButtonSubmit' style={{marginTop:"10px",fontSize:"13px",height:"4.5vh"}} onClick={submitHandle}>Submit</button>
                        </div>
                   </div>

                
                
                <div>
                <div >
                    <select
                      style={SelectSample}
                      value={sampleVal} onChange={(e) => onChangeSample(e.target.value)}
                    > 
                     <option style={optionStyle}>Sample Template</option>
                      <option style={optionStyle} value="Walmart">-Walmart</option>
                  
                      <option style={optionStyle}>-AbeBooks</option>
                     
                      <option style={optionStyle}>-Alibris</option>
                      <option style={optionStyle}>-eCampus</option>
                      <option style={optionStyle}>-ValoreBooks</option>
                      <option style={optionStyle}>-Biblio</option>
                      <option style={optionStyle}>-eBay</option>
                      <option style={optionStyle}>-Amazon.com</option>
                      <option style={optionStyle}>-Bookcommerce</option>
                    </select>
           
               </div>
      </div>


       </div>
       <div style={{display:"flex",width:"99%",justifyContent:"space-between"}}>
           <div style={{width:"20%"}}></div>
           <Stack sx={{ width: 'auto',position:"relative",top:"-40px",display:"flex" }} spacing={2}>
            {
                open ?
                <Alert  variant={errormsg==="something went wrong please try later" ? "filled" : null} severity={errormsg==="something went wrong please try later"? "error":"info"} sx={{padding:"0 6px"}}>
                  <span> {errormsg}! </span>
                  <CloseIcon sx={{marginLeft:"20px",cursor:"pointer"}} onClick={() =>{setOpen(false)}}/>
                </Alert> 
              : null
            } 

            {
              notMapped && 
             <Alert variant="filled" severity="error">
             <span>{errormsg}!</span>
             <CloseIcon sx={{marginLeft:"20px",cursor:"pointer"}} onClick={() =>{setNotMapped(false)}}/>
           </Alert> 
            }
           </Stack> 

          <div  className='bulkAddTemplate' onClick={()=>navigate("/bulkUploadAddtemplate")} style={{left:"0"}}>
                  <p>Add Template</p>
                  <p style={{fontSize:"20px"}}>+</p>
                </div>
        </div>
    
     
        
       </>
  )
}