import React, { useState,useContext, useEffect} from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../service/axiosInstance'
import "./account.style.scss"
import SignInSignUp from './SignInSignUp'
import { DataContext } from '../context/DataProvider'
import { Box,styled,Button } from '@mui/material'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { GrMail } from 'react-icons/gr';
import BookCommLogo from "./images/logo.png"
import FiMail from "./images/Path 858.png"
import PassLock from "./images/password.png"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Otpinput from './OtpInput'
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress'

const Dialog1=styled(Dialog)`& div > div{left:2%;border-radius:30px}`
const Box5=styled(Box)`position:absolute; z-index:2;top:73%; text-align:center;width:40.5vw`
const StyleHello={fontSize:"1.7rem",color: "#0E0B3A",fontFamily:"Poppins",fontWeight:"600",margin:"8px 0 5px 0"}
const Box7=styled(Box)`font: normal normal normal 21px Poppins;letter-spacing: 0px;color: #0E0B3A;`
const ButtonSignup=styled(Button)`background: var(--unnamed-color-13a74b) 0% 0% no-repeat padding-box;
background: #13A74B 0% 0% no-repeat padding-box;width:8vw;
box-shadow: 0px 3px 6px #00000017;
border-radius: 6px;color: #FFFFFF; &:hover{border-radius:50px;background: #13A74B}`


const LoginForm=styled(Box)`width:40vw;position:relative;left:42%;top:16%`

const SignUpLogin = ({ isUserAuthenticated }) => {
  const {setAccount, setWelcomValue} = useContext(DataContext);
  const navigate=useNavigate()
  const customId = "custom-id-yes";
  const [showSignUpForm,setShowSignUpForm]=useState(true)
  const [changeForm,setChangeForm]=useState("loginform")
  const [checked, setChecked] = React.useState(false);
  const [showCircle,setShowCircle]=useState(false)
    // for dialog box of otp verification
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    //for recieving props
  const getData=(data)=>{
    setShowSignUpForm(data)
  }

  //for login handle
  const initialValues={email:"",password:""}
  const [loginValue,setLoginValue]=useState(initialValues)
  const inputHandle=(e)=>{
      const {name,value}=e.target
      setLoginValue({ ...loginValue, [name]: value });
  }

  const loginFormHandle=async(e)=>{
    setShowCircle(true)
    e.preventDefault()
    /* console.log(loginValue) */
     try {
      await axiosInstance({
        url: "/login",
        method: "POST",
        data: loginValue,
      }).then((response) => {
        setShowCircle(false)
        if (response.data.message==="Login succesfully") {
          if (checked && loginValue.email !== "") {
            /* setCookie('checkbox', checked, { path: 'http://localhost:3000/signuplogin' }); */
            /* setCookie('email', loginValue.email, { path: 'http://localhost:3000/signuplogin' }); */
            /* setCookie('password', loginValue.password, { path: 'http://localhost:3000/signuplogin' }) */

           setCookie('checkbox', checked, { path: 'https://app.bookcommerce.com/signuplogin' });
           setCookie('email', loginValue.email, { path: 'https://app.bookcommerce.com/signuplogin' });
            setCookie('password', loginValue.password, { path: 'https://app.bookcommerce.com/signuplogin' })
            
          }
          toast.success("User Logged In Successfully")
            isUserAuthenticated(true)
            setLoginValue(initialValues)
            setAccount(response.data.user.firstname)
            localStorage.setItem("user",JSON.stringify(response.data.user))
            localStorage.setItem('accessToken', `Bearer ${response.data.token}`);
            setTimeout(()=>{
              navigate('/');
              window.location.reload(false);
              setWelcomValue(true)
            },0)
          }
        else if(response.data.message==="Not registered"){
          toast.error('This user does not exist!');
        }
        else if(response.data.message==="This userId and Password are not correct"){
          toast.error('Email id /phone number or password does not match!'); 
        } else{
          toast.error('Something went wrong please try later!')  
        }
        toast.clearWaitingQueue();  
      });
    } catch (e) {
      /* console.log(e); */
      if(e){
        setShowCircle(false)
          toast.error('Something went wrong please try later!');
        }
    }
            
  }

  //for remembe me functionality
  const [cookies, setCookie] = useCookies(['user'])
  useEffect(()=>{
    if (cookies.checkbox && cookies.email !== "") {
      setLoginValue({...loginValue,email:cookies.email,password:cookies.password})
    }
  },[])

  //for otp handle
  const [mobNumber,setMobNumber]=useState('')
  const customIdNew = "custom-id-yes";
  const getOtpHandle=async(e)=>{
        e.preventDefault()
        console.log(mobNumber)
        try{
          await axiosInstance({
        url: "/getOtp",
        method: "POST",
        data: {mobilenumber:mobNumber},
      }).then((res) => {
       console.log(res)
       localStorage.setItem("user",JSON.stringify(res.data.user))
       if(res.data.status==="success"){
        toast.success('OTP Send Succesfully',{
          toastId: customIdNew
        })
        setTimeout(()=>{
          handleClickOpen()
        }, 4000)
        
       }else{
         toast.error('This Mobile Number is not registered')
       }
       
      })
        }catch(e){
          toast.error('Something Went wrong, Please try later!')
        }
  }
  
  const [collectOtp,setCollectOtp]=useState('')
 const getOtp=(data)=>{
  setCollectOtp(data)
 }

  const veriFyOtpHandle=async(e)=>{
    e.preventDefault()
    const user = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
    try{
          await axiosInstance({
        url: "/verifyOtp",
        method: "POST",
        data: {otpCode:collectOtp,mobilenumber:user.mobilenumber},
      }).then((res) => {
       console.log(res)
       if(res.data.status==="success"){
        toast.success('OTP Verified Successfully', {
          toastId: customId
        })
        setTimeout(()=>{
          setChangeForm("resetpassword")
        handleClose()
        },3000)
       }else{
        toast.error('OTP does not match ')
       }
       
      })
        }catch(e){
          console.log(e)
          toast.error('Please fill out this field!')
        }
   
  }

  const [password,setPassword]=useState('')
  const [confirmPassword,setConfirmPassword]=useState('')
  const resetHandle=async(e)=>{
      e.preventDefault()
      const user = localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))
      console.log(user)
      const resetObj={}
      Object.assign(resetObj,{password:password,confirmPassword:confirmPassword,user:user})
      console.log("resetObj",resetObj)
      try{
          await axiosInstance({
        url: "/resetPassword",
        method: "POST",
        data: resetObj,
      }).then((res) => {
        if(res.data.status==="success"){
          toast.success("Password Changed Successfully")
          localStorage.clear()
          setTimeout(()=>{
            setChangeForm("loginform")
          },4000)
        }
        if(res.data.status==="incorrect format"){
          toast.error("Password must contain uppercase,lowercase letter,any special character with a length of 6 to 10")
        }
        if(res.data.status==="password not matched"){
          toast.error('Password and Confirm Password does not matched')
        }
        if(res.data.status==="failed"){
          toast.error("Both fields are required")
        }   
      })}catch(e){
        console.log(e)
        toast.error('Something went wrong, Please try later')
      }
  }

  const [changIcon,setChangIcon]=useState(true)
  const changeEyeIconHandle=()=>{
    setChangIcon(!changIcon)
  }

  const [changIcon2,setChangIcon2]=useState(true)
  const changeEyeIconHandle2=()=>{setChangIcon2(!changIcon2) }

  


  return (
    <>
    <ToastContainer
    limit={1}
    position="top-center"
    autoClose={3000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    /> 
    {
      showSignUpForm ?
       <div className='mainContainer'>
          <Box>
      
           <Box5>
                <Box style={StyleHello}>Hello, Friend!</Box>

                 <Box7>
                 Enter your details and start <br></br>
                 your journey with us.
                 </Box7>
                  <div style={{width:"8vw",borderBottom:"3px solid #0E0B3A",position:"relative",left:"39%",margin:"7px"}}></div>
                 <ButtonSignup onClick={()=>{setShowSignUpForm(false);setChangeForm('loginform')}}>SIGN UP</ButtonSignup>
           </Box5>
       
          </Box>
          <LoginForm>
                 <div className='signInFormContainer'>
                  <Box style={{textAlign:"center",height:"45px"}}><img width="50%" src={BookCommLogo} alt="loading.."/></Box>
                  {changeForm==="loginform" && <Box>
                  
                 
                       <h1 className='signIn_Header'>Sign In</h1>
                       
                       <p className='signIn_Ptag'>This is a secure system and you will need to <br></br> provide your login details to access the site.</p>
                          <form onSubmit={loginFormHandle}>
                                <div className='formInput' style={{padding:"2% 3% 2% 1.9%"}}>
                                  <div><img style={{width:"26px", height:"20px",paddingTop:"12px"}} src={FiMail} alt="lock..."/> <span style={{fontSize:"1.8rem"}}>|</span> 
                                  <input type='text' name="email" value={loginValue.email} onChange={inputHandle} placeholder='Enter your email or phone'/></div>
                              </div>
                              <div className='formInput'>
                                                <div> <img style={{width:"30px",paddingTop:"6px"}}  src={PassLock} alt="lock..."/> <span style={{fontSize:"1.8rem"}}>|</span> 
                                                <input type={changIcon ? "password" : "text"} name='password' value={loginValue.password}  onChange={inputHandle}  placeholder='Enter your password'/>
                                                 {changIcon ? <VisibilityIcon onClick={changeEyeIconHandle} sx={{margin:"9px 0 0 3px"}}/> : <VisibilityOffIcon onClick={changeEyeIconHandle} sx={{margin:"9px 0 0 3px"}}/>} </div>
                              </div>                                                                                                                                                                 
                              <div style={{display:"flex",justifyContent:"space-between",width:"94%"}}>
                              <div style={{padding:"9px 0 9px 9px"}}> 
                                    <input type='checkbox'
                                    style={{webkitAppearance: "auto"}}
                                    checked={checked}  onChange={(event)=>{setChecked(event.target.checked)}} /> <span style={{color:" #535353",opacity:"1"}}>Remember me</span></div>
                                  
                                  <div onClick={()=>{setChangeForm("getotp")}} style={{position:"relative",top:"8px",cursor:"pointer",color:" #535353"}}>Forgot/Reset Password</div>
                              </div>
                             <button type='submit'  className='login_Button'>LOGIN</button>
                            
                          </form>

                          <div style={{position: "relative",top: "-180px",width:"100%",textAlign: "center"}}>         
                              {
                                showCircle ? <CircularProgress disableShrink  color="success" size={35} thickness={5}/>  : null
                              }
                          </div>

                     </Box>  }
                     
                     {
                      changeForm==="getotp" && <Box>
                        <h1 className='signIn_Header' style={{margin:"35px 0 30 0"}}>Forgot password</h1>
                        <p className='signIn_Ptag' style={{margin:"20px 0 20 0"}}>Please enter the mobile number used while <br></br> creating your account we'll send you <br></br> instruction to reset your password.</p>
                        <form onSubmit={getOtpHandle}>
                              <div className='formInput' style={{margin:"20px 0 30px 10px",padding:"2% 5.9% 2% 0px"}}>
                                  <div style={{padding:"6px"}}>
                                     <input type='text' name="phone" onChange={(e)=>setMobNumber(e.target.value)} placeholder='Enter your phone number '/>
                                  </div>
                              </div>
                              <button  type='submit' className='login_Button'>GET OTP</button>
                               
                        </form>
                              
                        <Dialog1
                          open={open}
                          onClose={handleClose}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description">
        
                               <DialogTitle id="alert-dialog-title"  >
                               </DialogTitle>
                               <DialogContent  >
                                 <DialogContentText id="alert-dialog-description" >
                                   <div className='otpContainer' style={{borderRadius:"20px"}}>
                                       <div className='otpMailIcon'><GrMail/></div>
                                       <div className='otpText'>OTP Verification</div>
                                        <div style={{marginLeft:"20%"}}>
                                            <Otpinput getOtp={getOtp}/>
                                        </div>
                                      <Button onClick={veriFyOtpHandle}  variant="contained" style={{background:"#13A74B",marginTop:"15px"}}>Verify</Button>
                                     
                                   </div>
                                 
                                 </DialogContentText>
                               </DialogContent>
                              
                               <DialogActions>
          
                               </DialogActions>
                    </Dialog1>
                     </Box>
                     }

                     {changeForm==="resetpassword" && <Box>
                       <h1 className='signIn_Header'>Reset your Password</h1>
                                <form onSubmit={resetHandle}>
                                <div className='formInput'>
                                  <div> 
                                      <img style={{width:"30px",paddingTop:"6px"}}  src={PassLock} alt="lock..."/> <span style={{fontSize:"1.8rem"}}>|</span> 
                                      <input type={changIcon ?'password' : 'text' }
                                       name="password"  onChange={(e)=>setPassword(e.target.value)} 
                                       placeholder='New password'/>

                                      {changIcon ? <VisibilityIcon onClick={changeEyeIconHandle} sx={{margin:"9px 0 0 3px"}}/> : <VisibilityOffIcon onClick={changeEyeIconHandle} sx={{margin:"9px 0 0 3px"}}/>}
                                  </div>
                              </div>
                              <div className='formInput'>
                                                <div> 
                                                <img style={{width:"30px",paddingTop:"6px"}}  src={PassLock} alt="lock..."/> <span style={{fontSize:"1.8rem"}}>|</span> 
                                                <input type={changIcon2 ?'password' : 'text' } 
                                                 name='confirmPassword'   onChange={(e)=>setConfirmPassword(e.target.value)} 
                                                 placeholder='Confirm password'/> 
                                                {changIcon2 ? <VisibilityIcon onClick={changeEyeIconHandle2} sx={{margin:"9px 0 0 3px"}}/> : <VisibilityOffIcon onClick={changeEyeIconHandle2} sx={{margin:"9px 0 0 3px"}}/>}
                                               </div>
                              </div>                                                                                                                                                        
                             
                             <button type='submit'  className='login_Button'>SUBMIT</button>
                          
                          </form>
                         
                     </Box>  }
                    
                 </div>
          
          </LoginForm>
           
    </div> : <SignInSignUp getData={getData}/>
    }
    </>
  )
}

export default SignUpLogin




