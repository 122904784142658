export const COLUMNS = [
  {
    Header: 'SKU',
    accessor: 'sku',
    sticky: 'left'
  },
 
  {
    Header: 'Template Name',
    accessor: 'Templatename',
    sticky: 'left'
  },
  {
    Header: 'Abbreviation',
    accessor: 'abbrevation',
   
  },
  {
    Header: 'Mapping Status',
    accessor: 'status'
  }
]