import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default function CircularLoader() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Stack spacing={10} direction="row"     margin={"1.5% 2% 1% 25%"}  >
      <CircularProgress
         size={20}
        thickness={8}
        color="success"
        variant="determinate"
        value={progress}
      />
    </Stack>
  );
}
