import React, {useEffect,useContext} from 'react'
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import axiosInstance from '../../service/axiosInstance';
import { DataContext } from '../context/DataProvider';

const Ebaycomponent = () => {
    const {setReloadChannel} = useContext(DataContext)
    let navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams();
    let search = useLocation().search
    const ebayTokenApi = async(codes) => {
       try{
        await axiosInstance({
          url:"/EbayTokenGenrate",
          method:"POST",
          data:{code:codes}
        }).then((res)=>{
          if(res.data.access_token){
                console.log("ebayUrl", res.data.access_token)
                setReloadChannel(true)
                navigate('/ebayCompleted')
              //  let newWin = window.open('https://app.bookcommerce.com/channels','anyname','width=10,height=1,left=5,top=3')
              //  newWin.close()
          }
        })
       }catch(e){
        console.log(e)
       }
    }

    useEffect(()=>{
        const code = new URLSearchParams(search).get('code')
        if(code){
          ebayTokenApi(code)
        }
        console.log("searchParams", code)
    }, [search])

  return (
    <div>ebaycomponent</div>
  )
}

export default Ebaycomponent