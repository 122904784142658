import React, { useState, useContext, useEffect} from 'react'
import './Channel.scss'
import { Button, styled } from "@mui/material"
import AmazonBookConnectHeading from "../Images/amazonBookHeading.png"
import AmazonIcon from "../Images/amzon.png"
import { useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { DataContext } from '../context/DataProvider'
import axiosInstance from '../../service/axiosInstance'
import CircularProgress from '@mui/material/CircularProgress'
import { ToastContainer, toast } from 'react-toastify';
import { API } from "../../service/Api"

const Btn = styled(Button)`font: normal normal normal 15px Poppins;background: #E2E2E2 0% 0% no-repeat padding-box;
letter-spacing: 0px;text-transform: capitalize;
color: #282C45;margin-right:20px;width:8vw;height:5.5vh; &:hover{background-color:#E2E2E2}`
const Btn1 = styled(Button)`font: normal normal normal 15px Poppins;background: #13A74B 0% 0% no-repeat padding-box;
letter-spacing: 0px;text-transform: capitalize;width:8vw;height:5.5vh;
color: #FFFFFF; &:hover{background-color:#13A74B}`

const AmazonBookConnect = (props) => {
  const {editChannelDetails,setEditChannelDetails,setTopHeader}=useContext(DataContext)
  const navigate = useNavigate()
  const customId = "custom-id-yes";
  const [id, setId] = useState("")
  const [amazonselect, setAmazonselect] = useState("")
  const [amazonenable, setAmazonenable] = useState(false)
  const [amazonconnect, setAmazonconnect] = useState(false)
  const [showMsg,setShowMsg]=useState(false)
  // let formValue = {}
  // const submitHandle = async (e) => {
  //   e.preventDefault()
  //   Object.assign(formValue, { "id": id });
  //   Object.assign(formValue, { "amazonselect": amazonselect });
  //   Object.assign(formValue, { "amazonenable": amazonenable });
  //   Object.assign(formValue, { "amazonconnect": amazonconnect });
  //   console.log(formValue)
  //   props.getData(true)
  //   // if(response.data.status==="succes"){
  //   //   setFormValue(inigtialValue) 
  //   // setShowMsg(true)
  //   // setTimeout(()=>{
  //   //   props.getData(true)
  //   // },1000)   
  //   // }
  // }


  const inigtialValue = { amazonsellerid: "", amazonpassword: "", amazonemailid: "" }
  const [formValue, setFormValue] = useState(inigtialValue)
  useEffect(()=>{  //for channel details edit purpose
    if('userid' in editChannelDetails){
      setFormValue(editChannelDetails)
    }
  },[editChannelDetails])
  const [showCircle,setShowCircle]=useState(false)
  const inputHandle = (e) => {
    const { name, value } = e.target
    setFormValue({ ...formValue, [name]: value })
  }

  const submitHandle = async (e) => {
    e.preventDefault()
    console.log("form", formValue)
    setShowCircle(true)
   try{
    await axiosInstance({
      url:"/AmazonCredentials",
      method:"POST",
      data:formValue
    }).then((res)=>{
      console.log(res)
      setShowCircle(false)
      if (res.data.status === "success"){
        toast.success('userid' in editChannelDetails ? "Connection edited succesfully" : "Connection succesfully")
        setTimeout(()=>{
          if('userid' in editChannelDetails){
            setTopHeader('')
            setEditChannelDetails({})
            navigate('/setting')
          }else{
            props.getData(true)
            setTopHeader('')
          }
        },1000)
      }else if(res.data.status === "failed"){
        toast.error('Provide credentials')
      }else{
        toast.error('Incorrect credentials', {
          toastId: customId
        })
      }
      
      })   
   }catch(e){
    console.log(e)
    toast.error('Something went wrong!')
   }
   }

  // const submitHandle = async (e) => {
  //   e.preventDefault()
  //   console.log("form", formValue)
  //   setShowCircle(true)
  //  try{
  //   await axiosInstance({
  //     url:"/AmazonCredentials",
  //     method:"POST",
  //     data:formValue
  //   }).then((res)=>{
  //     console.log(res)
  //     setShowCircle(false)
  //     if (res.data.status === "success"){
  //       setFormValue(inigtialValue)
  //       toast.success('userid' in editChannelDetails ? "Connection edited succesfully" : "Connection succesfully")
  //       setTimeout(()=>{
  //         if('userid' in editChannelDetails){
  //           setTopHeader('')
  //           setEditChannelDetails({})
  //           navigate('/setting')
  //         }else{
  //           props.getData(true)
  //           setTopHeader('')
  //         }
  //       },1000)
  //     }else if(res.data.status === "failed"){
  //       toast.error('Incorrect credentials', {
  //         toastId: customId
  //       })
  //     }
  //     })   
  //  }catch(e){
  //   console.log(e)
  //   toast.error('Something went wrong!')
  //  }
  //  }


  // const submitHandle1=async(e)=>{
  //   e.preventDefault()
  //   setShowCircle(true)
  //   // console.log(formValue)
  //   let response = await API.AmazonCredentials(formValue);
  //   if (response.data.status === "succes") {
  //     setFormValue(inigtialValue)
  //     toast.success('userid' in editChannelDetails ? "Connection edited succesfully" : "Connection succesfully")
  //     setTimeout(()=>{
  //       if('userid' in editChannelDetails){
  //         setTopHeader('')
  //         setEditChannelDetails({})
  //         navigate('/setting')
  //       }else{
  //         props.getData(true)
  //         setTopHeader('')
  //       }
  //     },1000)
  //   }
  //   else{
  //     setShowCircle(false)
  //     toast.error("Login incorrect", {
  //       toastId: customId
  //     })
  //  }
  // }



   const cancelHandle = () => {
    if('userid' in editChannelDetails){
      navigate('/setting')
    }else{
      props.getData(true)
      setTopHeader('Channels')
    }
  }


 

  return (
    <div >
      <div className='abeBookConnectContainer'>
      <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            /> 
        <div>
          <div style={{ width: "40vw", textAlign: "center", paddingLeft: "15px", marginBottom: "10px" }}><img style={{ width: "505px", textAlign: "center" }} alt="loading" src={AmazonBookConnectHeading} /></div>
          <div className='abeBookForm' style={{ height: "39vh" }}>
            <div className='heading' style={{}}>You need a Amazon.com Seller account to sell Amazon.com</div>
            {/* <form onSubmit={submitHandle}>
              <div style={{ display: "flex", width: "100%" }}>
                <div>
                  <label htmlFor="text">Amazon.com Seller ID</label>
                  <input type="text"
                    name='id'
                    value={id}
                    onChange={(e) => setId(e.target.value)}
                    style={{ width: "19vw", marginRight: "15x" }} />
                </div>
                <div>
                  <label htmlFor="text">Amazon.com</label>
                  <select
                    name='amazonselect'
                    value={amazonselect}
                    onChange={(e) => setAmazonselect(e.target.value)}
                    style={{
                      border: "none", background: "#F6F7F8 0% 0% no-repeat padding-box",
                      borderRadius: "9px",
                      width: "19.5vw", height: "5.3vh"
                    }}>
                    <option>Amazon.com</option>
                    <option>Amazon.ca(Canada)</option>
                  </select>
                </div>
              </div>
              <div style={{ display: "flex", marginTop: "2%", marginBottom: "2%" }}>
                <div><label>Enable fulfillment by Amazon.com</label></div>
                <div style={{ position: "relative", left: "48%" }}>
                  <label className="switch">
                    <input type="checkbox"
                      name='amazonenable'
                      value={amazonenable}
                      onChange={(e) => setAmazonenable(e.target.checked)}
                      style={{ width: "45px" }} />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
              <div className='connectLogo_container'>
                <div><img style={{ width: "35px", height: "35px" }} src={AmazonIcon} alt="loading..." /></div>
                <div>Connect to Your Amazon.com Seller Account</div>
                <div>
                  <input
                    type='checkbox'
                    name='amazonconnect'
                    value={amazonconnect}
                    onChange={(e) => setAmazonconnect(e.target.checked)}
                    style={{ width: "20px", height: "20px" }} />
                </div>
              </div>
              <div style={{ width: "40vw", textAlign: "center", marginTop: "50px" }}>
                <Btn onClick={cancelHandle}>Cancel</Btn>
                <Btn1 type='submit'>Connect</Btn1>
              </div>
            </form> */}

            <form onSubmit={submitHandle}>
              <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "20.5vw", marginRight: "10px" }}>
                  <label htmlFor="text">Amazon seller account</label>
                  <input type="text"
                    name='amazonemailid'
                    value={formValue.amazonemailid}
                    onChange={inputHandle}
                    style={{ width: "18vw" }} />
                </div>

                <div>
                  <label htmlFor="text">Amazon Seller Id</label>
                  <input type="text"
                    name='amazonsellerid'
                    value={formValue.amazonsellerid}
                    onChange={inputHandle} required
                  />
                </div>
              </div>

              <div>
                <label>Amazon seller Password</label> <br></br>
                <input type="text"
                  name='amazonpassword'
                  value={formValue.amazonpassword}
                  onChange={inputHandle}
                  style={{ width: "39vw" }} />
              </div>

              <div style={{ width: "40vw", textAlign: "center", marginTop: "10px" }}>
                    {
                      showCircle ? <CircularProgress disableShrink  color="success" size={35} thickness={5}/>  : null
                    }<br></br>
                <Btn onClick={cancelHandle}>Cancel</Btn>
                <Btn1 type='submit'>Connect</Btn1>
              </div>
            </form>
          </div>
            <Stack sx={{ width: '100%',marginTop:"2%"}} spacing={2}>         
                          {
                            showMsg && 
                            <Alert sx={{borderRadius:"15px"}} variant="filled" severity="success">
                                 Connection succesfully
                            </Alert>
                          }
            </Stack> 
          <div style={{ width: "40vw", textAlign: "center", marginTop: "40px" }}>
            <span style={{ font: "normal normal medium 22px/13px Poppins", color: "#87859D" }}>Don't have an account?</span>
            
             <a style={{textDecoration:"none"}} href='https://services.amazon.com'  target="_blank" >
               <span style={{ font: "normal normal medium 22px/13px Poppins", color: "#0E0B3A" }}> Register Now</span>
             </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AmazonBookConnect