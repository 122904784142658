import  React,{useContext} from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import { BsThreeDotsVertical } from 'react-icons/bs';
import DeleteMappingPopper from './DeleteMappingPopper';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../context/DataProvider';

export default function ActionPopperMapping({deleteTempName,compoName,setForReload}) {
  const {setEditTempName}=useContext(DataContext)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState();
  const navigate=useNavigate()
  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };


  const redirectMapping=()=>{
      setEditTempName(deleteTempName)
      navigate('/bulkUploadAddtemplate')
  }

  return (
    <Box >
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Typography sx={{ p: 1,cursor:"pointer",font: "normal normal medium 10px/25px Poppins",letterSpacing: "0px",color: "#282C45" }}>
                <span onClick={redirectMapping}>Edit Mapping</span>
              </Typography>
              <Typography sx={{ p: 1,cursor:"pointer",font: "normal normal small 10px/25px Poppins",letterSpacing: "0px",color: "#282C45" }}> 
                <DeleteMappingPopper setForReload={setForReload} deleteTempName={deleteTempName} compoName={compoName}/>
              </Typography>
            </Paper>
          </Fade>
        )}
      </Popper>
     
      <Grid container justifyContent="center">
        <Grid item>
          <span onClick={handleClick('bottom-end')}><BsThreeDotsVertical/></span>
        </Grid>
      </Grid>
    </Box>
  );
}
