export const API_NOTIFICATION_MESSAGES = {
    loading: {
        title: "Loading...",
        message: "Data is being loaded. Please wait"
    },
    success: {
        title: "Success",
        message: "Data successfully loaded"
    },
    requestFailure: {
        title: "Error!",
        message: "An error occur while parsing request data"
    },
    responseFailure: {
        title: "Error!",
        message: "An error occur while fetching response from server. Please try again"
    },
    networkError: {
        title: "Error!",
        message: "Unable to connect to the server. Please check internet connectivity and try again."
    }
}

// API SERVICE URL
// SAMPLE REQUEST
// NEED SERVICE CALL: { url: "/", method: "POST/GET/PUT/DELETE" }
export const SERVICE_URLS = {
    //api for channels and its credentails
    Channel:{url:"/Channels", method:"GET"},
    walmartCredential:{url:'/WalmartCredentials', method:'POST'},
    valoreCredential:{url:'/ValoreCredentials', method:'POST'},
    abebookCredential:{url:'/AbeCredentials', method:'POST'},
    alibirsCredentials:{url:'/AlibirsCredentials', method:'POST'},
    biblioCredentials:{url:'/BiblioCredentials', method:'POST'},
    ebayCredentials:{url:'/EbayCredentials', method:'POST'},
    EbayTokenGenerate:{url:'/EbayTokenGenrate', method:'POST'},
    ecampusCredentials:{url:'/EcampusCredentials', method:'POST'},

    // for different channel listing
    AbebookListing:{url:'/abelisting', method:'GET'},
    AlibrisListing:{url:'/alibirslisting', method:'GET'},
    BiblioListing:{url:'/bibliolisting', method:'GET'},
    ValoreBookListing:{url:'/valorelisting', method:'GET'},
    EcampusListing:{url:'/ecampuslisting', method:'GET'},
    WalmartListing:{url:'/walmartlisting', method:'GET'},
    //API for inventory 
    GetCatalog:{url:'/getCatalog',method:'GET'},
    AddTemplate:{url:'/addTemplate', method:'POST'},
    MapTemplate:{url:'/mapTemplate', method:'POST'},
    UpdateexportCatalog:{url:'/updateexportCatalog',method:'POST'},
    InventoryListings:{url:"/listings",method:"GET", query:true},
    InventoryListingsDateFilter:{url:"/datefilter",method:"POST"},
    GetCatalogListingsDateFilter:{url:"/getcatalogdatefilter",method:"POST"},
    Bulkuploadlistings:{url:"/bulkuploadlistings",method:"GET", query:true},
    BulkuploadTable:{url:"/bulkupload",method:"GET"},

    //api for reports
       //Bar section
    Onedayproducts:{url:"/onedayproducts",method:"GET"},
    Sevendaysproducts:{url:"/sevendaysproducts",method:"GET"},
    Onemonthproducts:{url:"/onemonthproducts",method:"GET"},
    Allproducts:{url:"/allproducts",method:"GET"},
      
      //stock section
    Lowinstockproducts:{url:"/lowinstockproducts",method:"POST"}, 
    Mostinstockproducts:{url:"/mostinstockproducts",method:"POST"} 
  
}



