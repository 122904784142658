import React from 'react'

const EbayAuthcompleted = () => {
  return (
    <div 
    style={{width:"100%",height:"400vh",
    display:"flex",alignItems:"center",
    justifyContent:"center",
    position: "absolute",
    background: "white",
    top: 0,
    zIndex: 99}}
    >
        <h1>
            eBay Authentication Completed, Now You Can Close It and Refresh The Page.
        </h1>
    </div>
  )
}

export default EbayAuthcompleted