import axios from 'axios';
import { getAccessToken} from '../utils/common-utils';
import { API_URL } from './Api';

const axiosInstance = axios.create({
    baseURL: API_URL,
    // timeout: 10000, 
    headers: {
        "content-type": "application/json",
        authorization: getAccessToken(),
    }
});

export default axiosInstance