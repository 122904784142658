
import React,{useContext, useState} from 'react';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SuccessFullydeletedIcon from "../Images/successDeleted.png"
import WarningIcon from "../Images/warning.png"
import { DataContext } from '../context/DataProvider';
import {API} from "../../service/Api"
import CircularLoader from './CicularLoader';
const Box1=styled(Box)`width: 80vw;
height: 90vh;
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 50px;`
const Box2=styled(Box)`display:flex;width:80vw;height:5vh;margin-bottom:-13px;& div{width:17vw;height:4vh;font: normal normal normal 18px/33px Poppins;
letter-spacing: 0px;text-align:left;padding-left:44px;
color: #000000}`
const Box3=styled(Box)`display:flex;width:80vw;height:6.5vh;background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0px 4px 14px #00000017; margin:10px 0;padding-left:20px;
border-radius: 50px;& div{width:19vw;height:6vh;font: normal normal normal 18px/33px Poppins;
letter-spacing: 0px;text-align:left;padding:4px 0px 4px 0px;display:flex;
color: #000000}`
const Typography1=styled(Typography)`font: normal normal bold 20px/38px Poppins;letter-spacing: 0px;color: #000000;height:5vh`
const Button1=styled(Button)`text-transform:capitalize;margin-right:4%;color:#282C45;font: normal normal medium 15px/58px Poppins;background: #E2E2E2 0% 0% no-repeat padding-box;
border-radius: 10px;width:6vw;&:hover{background:#E2E2E2}`
const Button2=styled(Button)`text-transform:capitalize;color:#FFFFFF;font: normal normal medium 15px/58px Poppins;background: #13A74B 0% 0% no-repeat padding-box;
border-radius: 10px;width:6vw; &:hover{background:#13A74B}`

const Circle=styled(Box)`border-radius:10px;border: 4px solid #13A74B;margin-right:10px;margin-top:6px`
const CircleGray=styled(Box)`border-radius:10px;border: 4px solid #707070;margin-right:10px;margin-top:6px`

const TypographyLoader=styled(Typography)`text-align: center;width:35vw;height:5vh;margin-top:10px;
font: normal normal 600 16px/35px Poppins;display:flex;
letter-spacing: 0px;
color: #282C45;`

const BoxDoyouWant=styled(Box)`width:30vw;height:12vh;text-align:center;margin:20px`
const TypographyDoYouWant=styled(Typography)`text-align: center;width:35vw;height:5vh;margin-top:10px;
font: normal normal 600 16px/35px Poppins;
letter-spacing: 0px;
color: #282C45;`
const BoxDoyouWant1=styled(Box)`width:30vw;height:6vh;text-align:center;margin:20px;& button{margin-right:20px;text-transform:capitalize;width:6vw}`
const style = {
  position: 'absolute',
  top: '2%',
  left: '8%',
  bottom: "10%",
  width: "80vw",
  height: "90vh",
  bgcolor: 'background.paper',
  borderRadius:10,
  boxShadow: 24,
  p: 3,
};
const successContStyle={ position: "absolute",
    top: "30%",
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    left:"35%",borderRadius:"20px",padding:"10px",
    height: "35vh",
    width:"32vw",overflowX:"hidden",}

const InputStyle={background: "#EBE9E9 0% 0% no-repeat padding-box",
  border: "1px solid #E2E2E2",height:"5vh",width:"15vw",
  borderRadius: "28px",}


export default function ExportListing(props) {
  const {exporrtSelectedItem_id } = useContext(DataContext);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
 
  const handleClose = () => {
    setOpen(false);
    /* navigate('/invetoryListings') */
  };

  const [success,setSuccess]=React.useState(true)
  const [showDoyou,setShowDoyou]=React.useState(true)
  const loader=true

   //selectedchannelname
  const channelName=[]
  const [walmartName,setWalmartName]=useState(false)
  const [alibrisName,setAlibrisName]=useState(false)
  const [AbebooksName,setAbebooksName]=useState(false)
  const [eCampName,setECampName]=useState(false)
  const [valoreName,setValoreName]=useState(false)
  const [bibName,setBibName]=useState(false)
  const [eBayName,setEBayName]=useState(false)
  const [amacaName,setAmacaName]=useState(false)
  const [amacoName,setAmacoName]=useState(false)

  //for exporting channelnames and itemsids
  const nextHandle=async()=>{
    if(walmartName){
      channelName.push("Walmart")
    }

    if(alibrisName){
      channelName.push("Alibris")
    }

    if(AbebooksName){
      channelName.push("AbeBooks")
    }

    if(eCampName){
      channelName.push("eCampus")
    }

    if(valoreName){
      channelName.push("ValoreBooks")
    }

    if(bibName){
      channelName.push("Biblio")
    }

    if(eBayName){
      channelName.push("eBay")
    }

    if(amacaName){
      channelName.push("Amazon.Ca")
    }

    if(amacoName){
      channelName.push("Amazon.com")
    }
    
    console.log(channelName)
    const newSelectedItem_id=[...new Set(exporrtSelectedItem_id)]
    const payloadObj={}
    Object.assign(payloadObj,{ "idd":newSelectedItem_id},{"marketplacename":channelName})
    console.log(payloadObj)
    let response=await API.ExportBookCatalog(payloadObj)
    console.log(response) 
     if(response.isSuccess){
        setSuccess(false)
     }
    
  }

  //for circle change
  const [walCircle,setWalCircle]=useState(true)
  const [abeCircle,setAbeCircle]=useState(true)
  const [aliCircle,setAliCircle]=useState(true)
  const [eCaCircle,setECaCircle]=useState(true)
  const [valoCircle,setValoCircle]=useState(true)
  const [bibCircle,setBibCircle]=useState(true)
  const [ebayCircle,setEbayCircle]=useState(true)
  const [amacaCircle,setAmacaCircle]=useState(true)
  const [amacoCircle,setAmacoCircle]=useState(true)
 
  return (
    <div>
      
      <span onClick={handleOpen}>Export Listings</span>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

      {success ? 
        <Box sx={style}>
        <Box1>
                 <Typography1>Select Channel</Typography1>
                  <Box2>
                    <Box>Channels</Box>
                    <Box>Profit Percentage</Box>
                    <Box>Profit Price</Box>
                    <Box>Shipping Price</Box>
                  </Box2>

                  <Box3>
                    <Box style={{paddingTop:"8px"}}>
                        {
                          walCircle ? <CircleGray onClick={()=>{setWalCircle(!walCircle);setWalmartName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setWalCircle(!walCircle);setWalmartName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                       Walmart
                     </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <Box3>
                  <Box style={{paddingTop:"8px"}}>
                        {
                          abeCircle ? <CircleGray onClick={()=>{setAbeCircle(!abeCircle);setAbebooksName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setAbeCircle(!abeCircle);setAbebooksName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                        
                        
                        AbeBooks
                   </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <Box3>
                  <Box style={{paddingTop:"8px"}}>
                        {
                          aliCircle ? <CircleGray onClick={()=>{setAliCircle(!aliCircle);setAlibrisName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setAliCircle(!aliCircle);setAlibrisName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                        Alibris
                  </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <Box3>
                    <Box style={{paddingTop:"8px"}}>
                        {
                          eCaCircle ? <CircleGray onClick={()=>{setECaCircle(!eCaCircle);setECampName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setECaCircle(!eCaCircle);setECampName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                     eCampus
                     </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <Box3>
                    <Box style={{paddingTop:"8px"}}>
                        {
                          valoCircle ? <CircleGray onClick={()=>{setValoCircle(!valoCircle);setValoreName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setValoCircle(!valoCircle);setValoreName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                     ValoreBooks
                     </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <Box3>
                    <Box style={{paddingTop:"8px"}}>
                        {
                          bibCircle ? <CircleGray onClick={()=>{setBibCircle(!bibCircle);setBibName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setBibCircle(!bibCircle);setBibName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                     Biblio
                     </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <Box3>
                    <Box style={{paddingTop:"8px"}}>
                        {
                          ebayCircle ? <CircleGray onClick={()=>{setEbayCircle(!ebayCircle);setEBayName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setEbayCircle(!ebayCircle);setEBayName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                     eBay
                     </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <Box3>
                    <Box style={{paddingTop:"8px"}}>
                        {
                          amacaCircle ? <CircleGray onClick={()=>{setAmacaCircle(!amacaCircle);setAmacaName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setAmacaCircle(!amacaCircle);setAmacaName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                     Amazon.Ca
                     </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <Box3>
                    <Box style={{paddingTop:"8px"}}>
                        {
                          amacoCircle ? <CircleGray onClick={()=>{setAmacoCircle(!amacoCircle);setAmacoName(true)}} style={{width:"12px",height: "12px",padding:"0"}}></CircleGray> : 
                                      <Circle onClick={()=>{setAmacoCircle(!amacoCircle);setAmacoName(false)}} style={{width:"12px",height: "12px",padding:"0"}}></Circle> 
                        }
                    Amazon.com
                    </Box>
                    <Box ><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                    <Box><input style={InputStyle} type='text'/></Box>
                  </Box3>

                  <div style={{width:"15vw",height:"12vh",margin:"auto",marginTop:"30px"}}>
                  <Button1 variant="contained" onClick={handleClose} style={{color: "#282C45"}}    >Cancel</Button1>
                  <Button2 variant="contained" onClick={nextHandle} > Next </Button2>
                 </div>

        </Box1>
        </Box> :
        <>
           {
             showDoyou ? 
              <Box style={successContStyle}>
                  <BoxDoyouWant><img src={WarningIcon} width="90px" alt="warning..."/></BoxDoyouWant>

                  <TypographyDoYouWant style={{color: "#000000"}}>Do you want to export listings to marketplace? </TypographyDoYouWant>
                  <BoxDoyouWant1>
                      <Button onClick={()=>{setSuccess(true)}} variant="contained"  style={{background:"#E2E2E2",color: "#282C45",position:"relative",left:"10px"}}>No</Button>
                      <Button style={{position:"relative",left:"10px"}} onClick={()=>{setShowDoyou(false)}}  variant="contained" color="success">yes</Button>
                  </BoxDoyouWant1>
              </Box> :     
                 <> 
                   {
                    loader ? 
                    <div style={successContStyle}  >
                     <Box>
                        <TypographyLoader > <CircularLoader/> Export Listing to AbeBooks</TypographyLoader>
                        <TypographyLoader > <CircularLoader/> Export Listing to ValoreBooks</TypographyLoader>
                        <TypographyLoader > <CircularLoader/> Export Listing to Amazon.ca</TypographyLoader>
     
                     </Box>
                    </div>
                    :
                    <div className='successPopupContainer'>
                      
                      <div className='successPopupImage'><img src={SuccessFullydeletedIcon} width="110px" alt="warning..."/></div>
                      <h3 className='successPopupHeading'> Successfully exported to the marketplace </h3>
                    
                    
                      <div className='successPopupBtnCont'>
                      <button className='btn'   variant="contained" >OK</button>
                      </div>
                    </div>
                   }

                   </>
           }
       
        </>
      }
  
      </Modal>
    </div>
  );
}
