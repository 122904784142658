import React, { useState} from 'react'
import "./account.style.scss"
import axiosInstance from '../../service/axiosInstance'
import { Box, styled, Button } from '@mui/material'
import BookCommLogo from "./images/logo.png"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ToastContainer, toast } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress'

const Box5 = styled(Box)`position:absolute; z-index:2;top:73%;width:40.5vw;
border-top-right-radius: 146px;text-align:center; & img{width:66%}`
const StyleHello = { fontSize: "1.7rem", color: "#0E0B3A", fontFamily: "Poppins", fontWeight: "600", margin: "8px 0 5px 0" }
const Box7 = styled(Box)`font: normal normal normal 21px Poppins;letter-spacing: 0px;color: #0E0B3A;`
const ButtonSignup = styled(Button)`background: var(--unnamed-color-13a74b) 0% 0% no-repeat padding-box;
background: #13A74B 0% 0% no-repeat padding-box;width:8vw;
box-shadow: 0px 3px 6px #00000017;
border-radius: 6px;color: #FFFFFF; &:hover{border-radius:50px;background: #13A74B}`

//right box styling
const SignupForm = styled(Box)`width:40vw;position:relative;left:44%;top:3%`

const SignInSignUp = (props) => {
    const initialValues = { firstname: "", lastname: "", username: "", email: "", mobilenumber: "", password: "", confirmPassword: "" };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [showCircle,setShowCircle]=useState(false)
    const handleInput = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }
    const handleSignup_Form = async (e) => {
        e.preventDefault()
        setShowCircle(true)
        setFormErrors(ValidateForm(formValues))
    try{
        await axiosInstance({
            url: "/register",
            method: "POST",
            data: formValues,
        }).then((response) => {
            setShowCircle(false)
            console.log(response)
            if (response.data.message === "registration succesfully") {
             setFormValues(initialValues);
             toast.success("User Registration  Successfully")
             setTimeout(()=>{
                props.getData(true)
               
             },4000)
         }
         else if(response.data.message === "email already exists"){
            toast.error('email already exists')
         }
         else if(response.data.message === "mobile number already exists"){
            toast.error('mobile number already exists')
         }

         else {
            toast.error('Unable to register')
         }
         toast.clearWaitingQueue();  
        })    
    }catch(e){
        setShowCircle(false)
        if(e){
            toast.error('Something went wrong please try later!')
        }
    }   
    }

  const ValidateForm = (formValues) => {
        const errors = {}
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        /* let regex = new RegExp('[a-z0-9]+@gmail.com') */
        if (!formValues.firstName) {
            errors.firstName = "first name is required!";
        }

        // var regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
        // if(!regName.test(formValues.name)){
        //     errors.firstName = "Enter the valid first name";
        // }


        // if (!((formValues.username.match(/^[a-zA-Z]*$/g) && formValues.username.match( /[0-9]/g) && formValues.username.length >= 4))) {
        //    errors.username = "Username must be Alphanumeric with more than 4 and less than 10 character"
        // }       

        if (!((formValues.username.match(/^[A-Za-z][A-Za-z0-9_]{4,11}$/g))) ){
            errors.username = "Username must be Alphanumeric with more than 4 and less than 10 character"
         } 



        if (formValues.mobilenumber.length !== 10) {
            errors.mobilenumber = "Mobile no. must be of 10 digit"
        }
        if (!regex.test(formValues.email)) {
            errors.email = "This is not a valid email format"
        }
        if (!(formValues.password.match(/[a-z]/g) && formValues.password.match(/[A-Z]/g) && formValues.password.match(/[0-9]/g) && formValues.password.match(/[^a-zA-Z\d]/g) && formValues.password.length >= 6 && formValues.password.length < 11)) {
                   errors.password = "Password must contain uppercase,lowercase letter,any special character with a length of 6 to 10"
        }
        if (!(formValues.password === formValues.confirmPassword)) {
            errors.confirmPass = "Password and confirm password does not match"
        }
        return errors
    }

     const [changIcon,setChangIcon]=useState(true)
     const changeEyeIconHandle=()=>{
     setChangIcon(!changIcon) }
     const [changIcon2,setChangIcon2]=useState(true)
     const changeEyeIconHandle2=()=>{
     setChangIcon2(!changIcon2) }
    return (

        <div className='mainContainer'>
      
            <Box>
                <Box5>
                    <Box style={StyleHello}>Welcome Back</Box>
                    <Box7>
                        To keep connected with us please <br></br>
                        login using your login details.
                    </Box7>
                    <div style={{ width: "8vw", borderBottom: "3px solid #0E0B3A", position: "relative", left: "39%", margin: "7px" }}></div>
                    <ButtonSignup onClick={() => { props.getData(true) }}>SIGN IN</ButtonSignup>
                </Box5>
            </Box>
            <SignupForm>


                <div className='signInFormContainer'>
                    <Box style={{ textAlign: "center", height: "45px" }}><img width="50%" src={BookCommLogo} alt="loading.." /></Box>
                    <h1 className='signIn_Header'>Create Account</h1>

                    <p className='signIn_Ptag'>Please fill the form to create your account.</p>


                    <form onSubmit={handleSignup_Form}>


                        <div style={{ display: "flex", marginTop: "-20px" }}>
                            <div className='formInput1'>
                                <div><input type='text' onChange={handleInput} name="firstname" value={formValues.firstname} pattern="[a-zA-Z]{1,}" placeholder='First name' required />

                                </div>
                            </div>

                            <div className='formInput1'>
                                <div><input type='text' onChange={handleInput} name="lastname" value={formValues.lastname} pattern="[a-zA-Z]{1,}" placeholder='Last name' /></div>
                            </div>
                        </div>

                        <div style={{ display: "flex", marginTop: "-6px" }}>
                            <div className='formInput1'>
                                <div><input type='text' onChange={handleInput} name="username" value={formValues.username} placeholder='Username' required />

                                </div>                   <div style={{ padding: "15px", width: "119%", color: "darkred", fontSize: "12px", marginLeft: "-36px" }}>{formErrors.username}</div>
                            </div>


                            <div className='formInput1'>
                                <div><input type='text' onChange={handleInput} name="email" value={formValues.email} placeholder='E-mail address' required /></div>
                                <div style={{ padding: "21px", position: "absolute", color: "darkred", fontSize: "12px", marginLeft: "-36px" }}>{formErrors.email}</div>
                            </div>

                        </div>
                        <div className='formInput2' style={{ marginTop: "24px" }}>
                            <div> <input type='number' onChange={handleInput} name="mobilenumber" value={formValues.mobilenumber} placeholder='Mobile number' required /></div>

                        </div>
                        <p style={{ paddingBottom: "6px", position: "relative", zIndex: 10000, color: "darkred", fontSize: "12px", height: "0vh", marginLeft: "12px", marginTop: "-2px", marginBottom: "15px" }}>{formErrors.mobilenumber}</p>
                        <div className='formInput2'>
                            <div>
                               <input type={changIcon ? "password" : "text"} onChange={handleInput} name="password" value={formValues.password} placeholder='Password' required />
                               {changIcon ? <VisibilityIcon onClick={changeEyeIconHandle} sx={{marginLeft:"3px"}}/> : <VisibilityOffIcon onClick={changeEyeIconHandle} sx={{marginLeft:"3px"}}/>}
                            </div>

                        </div>
                        <div style={{ padding: "1px", marginLeft: "11px", color: "darkred", fontSize: "12px" }}>{formErrors.password}</div>
                        <div className='formInput2'>
                            <div> 
                               <input type={changIcon2 ? "password" : "text"} onChange={handleInput} name="confirmPassword" value={formValues.confirmPassword} placeholder='Confirm password' required /> 
                               {changIcon2 ? <VisibilityIcon onClick={changeEyeIconHandle2} sx={{marginLeft:"3px"}}/> : <VisibilityOffIcon onClick={changeEyeIconHandle2} sx={{marginLeft:"3px"}}/>}
                            </div>

                        </div>
                        <div style={{ padding: "1px", marginLeft: "11px", color: "darkred", fontSize: "12px" }}>{formErrors.confirmPass}</div>

                        <button className='signup_Button' type='submit'>SIGN UP</button>

                    </form>
                   
                    <div style={{position: "relative",top: "-280px",width:"100%",textAlign: "center"}}>         
                              {
                                showCircle ? <CircularProgress disableShrink  color="success" size={35} thickness={5}/>  : null
                              }
                    </div>

                </div>

                          

                      

            </SignupForm>
        </div>
    )
}

export default SignInSignUp