import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import React, { useState, lazy, Suspense } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import DataProvider from "./Component/context/DataProvider";
import SignUpLogin from "./Component/Account/SignUpLogin";
import Ebaycomponent from "./Component/Channels/ebaycomponent";
import BulkUpload from "./Component/Inventory/BulkUpload";
import EbayAuthcompleted from "./Component/Channels/EbayAuthcompleted";
import AmazonBookConnect from "./Component/Channels/AmazonBookConnect";
// import Menubar from "./Component/Menubar/Menubar";
// import AmazonRepricing from "./Component/Inventory/AmazonRepricing";
// import Home from "./Component/Home/Home";
// import Channel from "./Component/Channels/Channel";
// import Notifications from "./Component/Notification/Notification";
// import Reports from "./Component/Reports/Reports";
// import Order from "./Component/Orders/Order";
// import OrderListings from "./Component/Orders/Orderlistings";
// import ExportOrders from "./Component/Orders/ExportOrder";
// import AddInventory from "./Component/Inventory/AddInventory";
// import BookCatalog from "./Component/Inventory/BookCatalog";
// import Listings from "./Component/Inventory/Listings";

// import ChannelSetting from "./Component/settings/ChannelSetting";
// import EmailSetting from "./Component/settings/EmailSetting";
// import AccountSetting from "./Component/settings/AccountSetting";
// import PurgeCatalog from "./Component/settings/PurgeCatalog";
// import BillingSetting from "./Component/settings/BillingSetting";
// import EditProductOrderListing from "./Component/Orders/OrderListingEditProduct";
// import Amazocalisting from "./Component/Channels/channelListing/Amazocalisting";
// import Amazonlisting from "./Component/Channels/channelListing/Amazonlisting";
// import AbeBookListing from "./Component/Channels/channelListing/AbeBookListing";
// import Ebaylisting from "./Component/Channels/channelListing/Ebaylisting";
// import Bibliolisting from "./Component/Channels/channelListing/Bibliolisting";
// import Ecampuslisting from "./Component/Channels/channelListing/Ecampuslisting";
// import Alibrislisting from "./Component/Channels/channelListing/Alibrislisting";
// import Valorelisting from "./Component/Channels/channelListing/Valorelisting";
// import Walmartlisting from "./Component/Channels/channelListing/Walmartlisting";
// import BulkUploadAddTemplate from "./Component/Inventory/BulkUpload_AddTemplat";
// import BulkUploadAddTemplateMapping from "./Component/Inventory/BulkUpload_Template_Mapping";
// import EditMappingTemplate from "./Component/Inventory/bulkupload/EditMappingTemplate";
// import ValoreBookConnect from "./Component/Channels/ValoreBookConnect";
// import AbeBookConnect from "./Component/Channels/AbeBookConnect";
// import WalmartBookConnect from "./Component/Channels/WalmartBookConnect";
// import EbayBookConnect from "./Component/Channels/EbayBookConnect";
// import BiblioBookConnect from './Component/Channels/BiblioBookConnect';
// import EcampusBookConnect from "./Component/Channels/EcampusBookConnect";
// import AliBrisBookConnect from "./Component/Channels/AlibrisBookConnect";
// import  TemplateListingTable  from "./Component/Inventory/templatelisting/TemplateListingTable";

// const BulkUpload = lazy(() => import("./Component/Inventory/BulkUpload"));
const Menubar = lazy(() => import("./Component/Menubar/Menubar"));
const AmazonRepricing = lazy(() => import("./Component/Inventory/AmazonRepricing"));
const TemplateListingTable = lazy(() => import("./Component/Inventory/templatelisting/TemplateListingTable"));
const Home = lazy(() => import("./Component/Home/Home"));
const Channel = lazy(() => import("./Component/Channels/Channel"));
const Notifications = lazy(() => import("./Component/Notification/Notification"));
const Reports = lazy(() => import("./Component/Reports/Reports"));
const Order = lazy(() => import("./Component/Orders/Order"));
const OrderListings = lazy(() => import("./Component/Orders/Orderlistings"));
const ExportOrders = lazy(() => import("./Component/Orders/ExportOrder"));
const AddInventory = lazy(() => import("./Component/Inventory/AddInventory"));
const EditInventory = lazy(() => import("./Component/Inventory/EditInventory"));
const BookCatalog = lazy(() => import("./Component/Inventory/BookCatalog"));
const Listings = lazy(() => import("./Component/Inventory/Listings"));
const ChannelSetting = lazy(() => import("./Component/settings/ChannelSetting"));
const EmailSetting = lazy(() => import("./Component/settings/EmailSetting"));
const AccountSetting  = lazy(() => import("./Component/settings/AccountSetting"));
const PurgeCatalog = lazy(() => import("./Component/settings/PurgeCatalog"));
const BillingSetting = lazy(() => import("./Component/settings/BillingSetting"));
const EditProductOrderListing = lazy(() => import("./Component/Orders/OrderListingEditProduct"));
// const Amazocalisting = lazy(() => import("./Component/Channels/channelListing/Amazocalisting")); 
const Amazonlisting = lazy(() => import("./Component/Channels/channelListing/Amazonlisting"));
const AbeBookListing = lazy(() => import("./Component/Channels/channelListing/AbeBookListing"));
const Ebaylisting = lazy(() => import("./Component/Channels/channelListing/Ebaylisting"));
const Bibliolisting = lazy(() => import("./Component/Channels/channelListing/Bibliolisting"));
const Ecampuslisting = lazy(() => import("./Component/Channels/channelListing/Ecampuslisting"));
const Alibrislisting = lazy(() => import("./Component/Channels/channelListing/Alibrislisting"));
const Valorelisting = lazy(() => import("./Component/Channels/channelListing/Valorelisting"));
const Walmartlisting = lazy(() => import("./Component/Channels/channelListing/Walmartlisting"));
const BulkUploadAddTemplate = lazy(() => import("./Component/Inventory/BulkUpload_AddTemplat"));
const BulkUploadAddTemplateMapping = lazy(() => import("./Component/Inventory/BulkUpload_Template_Mapping"));
const EditMappingTemplate = lazy(() => import("./Component/Inventory/bulkupload/EditMappingTemplate"));
const ValoreBookConnect = lazy(() => import("./Component/Channels/ValoreBookConnect"));
const AbeBookConnect = lazy(() => import("./Component/Channels/AbeBookConnect"));
const WalmartBookConnect = lazy(() => import("./Component/Channels/WalmartBookConnect"));
const EbayBookConnect = lazy(() => import("./Component/Channels/EbayBookConnect"));
const EcampusBookConnect = lazy(() => import("./Component/Channels/EcampusBookConnect"));
const AliBrisBookConnect = lazy(() => import("./Component/Channels/AlibrisBookConnect"));
const BiblioBookConnect = lazy(() => import("./Component/Channels/BiblioBookConnect"));


const PrivateRoute = ({ isAuthenticated, ...props }) => {
  // const token = sessionStorage.getItem('accessToken');
  const token = localStorage.getItem("accessToken");
  return token ? (
    <>
      <Menubar />
      <Outlet />
    </>
  ) : (
    <Navigate replace to="/signuplogin" />
  );
};

function App() {
  const [isAuthenticated, isUserAuthenticated] = useState(false);
  return (
    <div className="App">
      <DataProvider>
        <Suspense fallback={<CircularProgress color="success" sx={{position:"absolute",left:"50%",top:"40%"}} disableShrink />}>
          <BrowserRouter>
            {/* <Menubar/> */}
            <Routes>
              {/* <Route path="/ebay" element={<Ebaycomponent/>}/> */}

              <Route
                path="/ebay"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/ebay" element={<Ebaycomponent />} />
              </Route>

              <Route
                path="/ebayCompleted"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/ebayCompleted" element={<EbayAuthcompleted />} />
              </Route>

              <Route
                path="/signuplogin"
                element={
                  <SignUpLogin isUserAuthenticated={isUserAuthenticated} />
                }
              />

              <Route
                path="/"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/" element={<Home />} />
              </Route>
              {/* channel route */}
              <Route
                path="/channels"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/channels" element={<Channel />} />
              </Route>

              {/* <Route
                path="/amazoncalisting"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/amazoncalisting" element={<Amazocalisting />} />
              </Route> */}

              <Route
                path="/amazoncolisting"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/amazoncolisting" element={<Amazonlisting />} />
              </Route>

              <Route
                path="/abeBookListing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/abeBookListing" element={<AbeBookListing />} />
              </Route>

              <Route
                path="/ebayListing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/ebayListing" element={<Ebaylisting />} />
              </Route>

              <Route
                path="/biblioListing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/biblioListing" element={<Bibliolisting />} />
              </Route>

              <Route
                path="/ecampusListing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/ecampusListing" element={<Ecampuslisting />} />
              </Route>

              <Route
                path="/alibrisListing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/alibrisListing" element={<Alibrislisting />} />
              </Route>

              <Route
                path="/valorebookListing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/valorebookListing" element={<Valorelisting />} />
              </Route>

              <Route
                path="/walmartListing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/walmartListing" element={<Walmartlisting />} />
              </Route>

              {/* inventory routes */}
              <Route
                path="/inventory"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/inventory" element={<AddInventory />} />
              </Route>

              <Route
                path="/editInventory"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/editInventory/:id" element={<EditInventory />} />
              </Route>

              <Route
                path="/bulkUpload"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/bulkUpload" element={<BulkUpload />} />
              </Route>

              <Route
                path="/bulkUploadAddtemplate"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/bulkUploadAddtemplate"
                  element={<BulkUploadAddTemplate />}
                />
              </Route>

              <Route
                path="/templateListingTable"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/templateListingTable"
                  element={<TemplateListingTable />}
                />
              </Route>

              <Route
                path="/bulkUploadAddTemplateMapping"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/bulkUploadAddTemplateMapping"
                  element={<BulkUploadAddTemplateMapping />}
                />
              </Route>

              <Route
                path="/editMappingTemplate"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/editMappingTemplate"
                  element={<EditMappingTemplate />}
                />
              </Route>

              <Route
                path="/bookCatalog"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/bookCatalog" element={<BookCatalog />} />
              </Route>

              <Route
                path="/invetoryListings"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/invetoryListings" element={<Listings />} />
              </Route>

              <Route
                path="/amazonRepricing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/amazonRepricing" element={<AmazonRepricing />} />
              </Route>

              {/* order route  */}
              <Route
                path="/order"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/order" element={<Order />} />
              </Route>

              <Route
                path="/orderListings"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/orderListings" element={<OrderListings />} />
              </Route>

              <Route
                path="/exportOrder"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/exportOrder" element={<ExportOrders />} />
              </Route>

              <Route
                path="/editProductOrderListing"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/editProductOrderListing"
                  element={<EditProductOrderListing />}
                />
              </Route>

              {/* setting route  */}
              <Route
                path="/setting"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/setting" element={<ChannelSetting />} />
              </Route>
              {/* edit channel route  */}
              <Route
                path="/valoresettingedit"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/valoresettingedit"
                  element={<ValoreBookConnect />}
                />
              </Route>

              <Route
                path="/abebookssettingedit"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/abebookssettingedit"
                  element={<AbeBookConnect />}
                />
              </Route>

              <Route
                path="/walmartsettingedit"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/walmartsettingedit"
                  element={<WalmartBookConnect />}
                />
              </Route>

              <Route
                path="/ebaysettingedit"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/ebaysettingedit" element={<EbayBookConnect />} />
              </Route>

              <Route
                path="/bibliosettingedit"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/bibliosettingedit"
                  element={<BiblioBookConnect />}
                />
              </Route>

              <Route
                path="/ecampussettingedit"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/ecampussettingedit"
                  element={<EcampusBookConnect />}
                />
              </Route>

              <Route
                path="/alibrissettingedit"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/alibrissettingedit"
                  element={<AliBrisBookConnect />}
                />
              </Route>

              <Route
                path="/amazonsettingedit"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route
                  path="/amazonsettingedit"
                  element={<AmazonBookConnect />}
                />
              </Route>

              <Route
                path="/emailSetting"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/emailSetting" element={<EmailSetting />} />
              </Route>

              <Route
                path="/billingSetting"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/billingSetting" element={<BillingSetting />} />
              </Route>

              <Route
                path="/accountSetting"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/accountSetting" element={<AccountSetting />} />
              </Route>

              <Route
                path="/purgeCatalog"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/purgeCatalog" element={<PurgeCatalog />} />
              </Route>

              <Route
                path="/notification"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/notification" element={<Notifications />} />
              </Route>

              <Route
                path="/report"
                element={<PrivateRoute isAuthenticated={isAuthenticated} />}
              >
                <Route path="/report" element={<Reports />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </Suspense>
      </DataProvider>
    </div>
  );
}

export default App;
