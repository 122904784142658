import React, { useMemo,useEffect, useState } from 'react'

import { useTable, usePagination, useRowSelect, useSortBy, useGlobalFilter } from 'react-table'
import { COLUMNS } from './columns'
import nex from '../../Channels/channelListing/ValoreBooks/nex.png'
import "../../Channels/channelListing/ValoreBooks/listingtable.css"
import pre from '../../Channels/channelListing/ValoreBooks/pre.png'
import { Checkbox} from './Checkbox'
import { GlobalFilter } from './GlobalFilter'
import { BsThreeDots } from 'react-icons/bs';
import {TiArrowUnsorted,TiArrowSortedDown,TiArrowSortedUp} from 'react-icons/ti'
import ExportListing from '../ExportListing'
import {API} from "../../../service/Api"
import ActionPopperMapping from './ActionPopperMapping'

export const BulkUploadListingsTable = () => {

  const [fetchData,setFetchData]=useState([])
  const [templateName,setTemplateName]=useState([])
  const [forReload,setForReload] = useState(false)
  const getData=async()=>{
    let res=await API.BulkuploadTable()
    console.log(res)
    if(res.isSuccess===true){
      setFetchData(res.data.templatedetails)
      setTemplateName(res.data.templatename)
      setForReload(false)
    }
  }

  useEffect(()=>{
    getData()
  },[forReload])

  const columns = useMemo(() => COLUMNS, [])
  const data = useMemo(() => fetchData)
console.log("data",data)
console.log("fetchData",fetchData)
console.log("columns",columns)

  const {getTableProps,getTableBodyProps,headerGroups,page,nextPage,previousPage,canPreviousPage,canNextPage,gotoPage,pageCount,state,setGlobalFilter,prepareRow,state: { selectedRowIds }} = useTable({columns,
data,stateReducer: (newState, action) => {
  if (action.type === "toggleRowSelected") {
    newState.selectedRowIds = {
      [action.id]: true
    }
  }

  return newState;
},}, useGlobalFilter,useSortBy,usePagination,useRowSelect,hooks => {hooks.visibleColumns.push(columns => [{id: 'selection',
          Header: ({ getToggleAllRowsSelectedProps }) => (
            
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <Checkbox  {...row.getToggleRowSelectedProps()} />
        },...columns,
         {
          id: "Edit",
          Header: "Action",
          Cell: ({ row }) => (
            <div className="dropdown">
             
                <ActionPopperMapping setForReload={setForReload}  deleteTempName={row.original.Templatename}/>
              
            </div>
          )
        },

      ])
    }

  )
  const { globalFilter } = state
  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} templateName={templateName} />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup,i) => (
          
            <tr key={i+"1bulk"} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {
                    (column.Header==="cost Price" || column.Header==="Quantity")  &&
                    <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <TiArrowSortedDown />
                        : <TiArrowSortedUp/>
                      : <TiArrowUnsorted/>}
                  </span>
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td style={{ paddingLeft: "8px" }} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className='bottamcontainer'>
     <div className="exportboxcontainer">
          {/* <div>
            <button className="expo-button">
              <ExportListing />
            </button>
          </div> */}
        </div>
     
      <div className='paginationcontainer'>
      <div className="pagination">
             
             <a
               className="page-link "
               onClick={() => previousPage()}
               disabled={!canPreviousPage}
               href
             >
               <img src={pre} alt="loading..." />
             </a>
             <a
               className="page-link"
               style={{ color: "black" }}
               onClick={() => gotoPage(0)}
               disabled={!canPreviousPage}
               href
             >
             1
             </a>
             <a
               className="page-link"
               style={{ color: "black" }}
               onClick={() => gotoPage(pageCount - 1)}
               href
             >
               2
             </a>
             <a
               className="page-link"
               style={{ color: "black" }}
               onClick={() => gotoPage(pageCount - 1)}
               href
             >
               3
             </a>
             <a
               className="page-link"
               style={{ color: "black" }}
               onClick={() => gotoPage(pageCount - 1)}
               href
             >
               <BsThreeDots />
             </a>
             <a
               className="page-link"
               onClick={() => nextPage()}
               disabled={!canNextPage}
               href
             >
               <img src={nex} alt="loading..." />
             </a>
         </div>
      </div>
      </div>
      
    </>
  )
}
